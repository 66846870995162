import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'

const WaveMaterial = shaderMaterial(
  {
    time: 0,
    resolution: new THREE.Vector2(),
    pointer: new THREE.Vector2()
  },
  /*glsl*/ `
      varying vec2 vUv;
      void main() {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;
        gl_Position = projectionPosition;
        vUv = uv;
      }`,
  /*glsl*/ `
      uniform float time;
      uniform vec2 resolution;
      uniform vec2 pointer;
      varying vec2 vUv;

      // Noise function to create graininess
      float random(vec2 st) {
        return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
      }

      // Updated palette function to incorporate rgb(218,255,36)
      vec3 palette(float t) {
        vec3 base = vec3(0.263, 0.416, 0.557); // Base color
        vec3 targetColor = vec3(0.855, 1.0, 0.141); // rgb(218, 255, 36)

        // Interpolate between base and target color
        vec3 color = mix(base, targetColor, sin(t * 6.28318) * 0.5 + 0.5);
        return color;
      }

      void main() {
        // Adjust the UV coordinates and center them
        vec2 uv = (gl_FragCoord.xy * 2.0 - resolution.xy) / resolution.y;
        
        // Calculate radial distance from center
        float distanceFromCenter = length(uv);

        // Make the wave frequency higher for thinner bands
        float wave = sin(distanceFromCenter * 25.0 - time * 3.0) * 0.5 + 0.5;

        // Add some radial distortion based on pointer (or time for mobile)
        vec2 distortion = vec2(sin(pointer.x * 3.0 + time) * 0.1, cos(pointer.y * 3.0 + time) * 0.1);
        uv += distortion;

        // Use the updated palette function with the new colors
        vec3 col = palette(distanceFromCenter + time * 0.5);

        // Create an organic effect based on distance and sine wave
        float intensity = smoothstep(0.2, 0.7, wave);

        // Add grainy noise based on random UV and time
        float grain = random(uv + time) * 0.1; // Adjust 0.1 for more/less grain

        // Final color addition with intensity modulation and graininess
        vec3 finalColor = col * intensity + grain;

        // Add the color to the black background
        gl_FragColor = vec4(finalColor, 1.0);   
      }`
)

extend({ WaveMaterial })

export { WaveMaterial }
