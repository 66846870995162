import { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { WaveMaterial } from './WaveMaterial';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';

const isMobile = () => {
  return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);
};

function ShaderPlane({ isHovered }) {
  const ref = useRef();
  const { viewport, size } = useThree();

  useFrame((state, delta) => {
    ref.current.time += delta;
    ref.current.hovered = isHovered ? 1 : 0;
    if (!isMobile()) {
      ref.current.pointer.set(state.mouse.x * 0.5 + 0.5, state.mouse.y * 0.5 + 0.5);
    }
  });

  return (
    <mesh scale={[viewport.width, viewport.height, 1]}>
      <planeGeometry />
      <waveMaterial ref={ref} key={WaveMaterial.key} resolution={[size.width * viewport.dpr, size.height * viewport.dpr]} />
    </mesh>
  );
}

function Paperclip({ setIsHovered }) {
  const paperclipRef = useRef();
  const hitboxRef = useRef();
  const paperclip = useLoader(GLTFLoader, '/paperclip.glb');
  const [hovered, setHovered] = useState(false);
  const [rotationAxis, setRotationAxis] = useState(new THREE.Vector3(0, 1, 0)); // Random axis
  const [chaoticSpin, setChaoticSpin] = useState(false);
  const isMobileDevice = isMobile();
  const rotationSpeed = 0.02;
  const chaoticSpinDuration = 5;

  useEffect(() => {
    setHovered(true);
    setIsHovered(true);
  }, []);

  const handlePointerOver = () => {
    setHovered(true);
    setIsHovered(true);
    setChaoticSpin(true);
    setRotationAxis(new THREE.Vector3(Math.random(), Math.random(), Math.random()).normalize()); // Set a random axis on hover
  };

  const handlePointerOut = () => {
    setHovered(false);
    setIsHovered(false);
    setChaoticSpin(false);
  };

  const handleMobileClick = () => {
    if (isMobileDevice) {
      setChaoticSpin(true); 
      setTimeout(() => setChaoticSpin(false), chaoticSpinDuration * 1000);
    }
  };

  useFrame((state, delta) => {
    if (paperclipRef.current) {
      if (chaoticSpin) {
        // Chaotic spin with random axis
        paperclipRef.current.rotation.x += (1 + Math.random() * 2) * delta * 20;
        paperclipRef.current.rotation.y += (1 + Math.random() * 2) * delta * 20;
        paperclipRef.current.rotation.z += (1 + Math.random() * 2) * delta * 20;
      } else if (hovered) {
        // If hovered, spin along the randomly set axis
        paperclipRef.current.rotation.x += rotationSpeed * rotationAxis.x * delta * 10;
        paperclipRef.current.rotation.y += rotationSpeed * rotationAxis.y * delta * 10;
        paperclipRef.current.rotation.z += rotationSpeed * rotationAxis.z * delta * 10;
      } else {
        // Default rotation (mobile or non-chaotic)
        paperclipRef.current.rotation.x += rotationSpeed * delta * 10;
        paperclipRef.current.rotation.y += rotationSpeed * delta * 10;
        paperclipRef.current.rotation.z += rotationSpeed * delta * 10;
      }
    }
  });

  return (
    <>
      <primitive ref={paperclipRef} object={paperclip.scene} position={[0, 0, 3]} onClick={handleMobileClick} /> {/* Add onClick event */}
      <mesh
        ref={hitboxRef}
        scale={[4, 4, 4]}
        position={[0, 0, 0]}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <boxGeometry args={[1, 1, 1]} />
        <meshBasicMaterial visible={false} />
      </mesh>
    </>
  );
}

export default function App() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Canvas>
      <ShaderPlane isHovered={isHovered} />
      <ambientLight intensity={2} />
      <pointLight position={[0, 5, 15]} intensity={250} />
      <Paperclip setIsHovered={setIsHovered} />
    </Canvas>
  );
}
