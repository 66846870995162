export default function Overlay() {
  return (
    <>
      <div style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        pointerEvents: 'none',
        backgroundColor: 'rgba(128, 128, 128, 0.2)',
        backdropFilter: 'blur(4px)'
      }}>
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '20px', paddingTop: '4px' }}>
          <h1
            style={{
              fontSize: '52px',
            }}
          >
            Paperclip
          </h1>
          <a
            href="https://dexscreener.com/solana/dq5nazhgpgxek83tsqfub9444ny68krkvy5s2r1mraqw"
            target="_blank"
            style={{ pointerEvents: 'auto' }}
          >
            <img src="/moonshot-transparent.png" alt="Moonshot" style={{ height: '40px' }} />
          </a>
          <a
            href="https://jup.ag/swap/SOL-Dq5NAzhGPGxek83TSqFUB9444ny68kRKVy5S2R1mrAqw"
            target="_blank"
            style={{ pointerEvents: 'auto' }}
          >
            <img src="/jupiter-logo.svg" alt="Jupiter" style={{ height: '40px' }} />
          </a>
        </div>
      </div>
      <div style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '50px',
        overflow: 'hidden',
        pointerEvents: 'none',
        backgroundColor: 'rgba(128, 128, 128, 0.2)',
        backdropFilter: 'blur(4px)',
      }}>
        <div style={{ textAlign: 'center', paddingBottom: '8px' }}>
          <span
            style={{
              fontSize: '24px',
              fontStyle: 'italic',
              animation: 'pulse 10s infinite ease-in-out',
              lineHeight: '50px'
            }}
          >
            It's probably nothing...
          </span>
        </div>
      </div>
    </>
  );
}
